import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import * as ReactQuery from "@tanstack/react-query";
import type * as React from "react";

export const queryClient = new ReactQuery.QueryClient();

type QueryProviderProps = { children: React.ReactNode };

export function QueryClientProvider({ children }: QueryProviderProps) {
  return (
    <ReactQuery.QueryClientProvider client={queryClient}>
      {children}
    </ReactQuery.QueryClientProvider>
  );
}
