import axios from "axios";
import { LovenseSocketUrlSchema } from "../schemas/lovense-socket-url.js";
export async function getLovenseSocketUrl(token) {
    const response = await axios.post("https://api.lovense-api.com/api/basicApi/getSocketUrl", {
        platform: "fapinstructor",
        authToken: token,
    });
    const { data } = LovenseSocketUrlSchema.parse(response.data);
    if (!data) {
        throw new Error("Failed to retrieve lovense socket url.");
    }
    return data;
}
