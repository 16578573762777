import { Button, Card, Center, Group, Stack, Text, Title } from "@mantine/core";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Page } from "../_page";

export function PageNotFound() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const title = searchParams.get("title") ?? "Page Not Found";

  return (
    <Page title="Page Not Found">
      <Center h="100%">
        <Card withBorder>
          <Stack ta="center" gap="sm">
            <Title>{title}</Title>
            <Text maw={400}>
              It looks like this page doesn't exist. To return to where you were
              previously please click the below button.
            </Text>
            <Center>
              <Group>
                <Button
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Go Back
                </Button>
              </Group>
            </Center>
          </Stack>
        </Card>
      </Center>
    </Page>
  );
}
