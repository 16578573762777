// Execute promises in sequential order.
export class Queue {
  #queue: (() => Promise<void>)[] = [];
  #working = false;

  async enqueue(callback: () => Promise<void>) {
    this.#queue.push(callback);
    await this.#dequeue();
  }

  async #dequeue() {
    if (this.#working) {
      return;
    }

    const callback = this.#queue.shift();
    if (callback === undefined) {
      return;
    }
    this.#working = true;

    try {
      await callback();
    } catch (error) {
      console.log(error);
    } finally {
      this.#working = false;
    }

    await this.#dequeue();
  }

  async clear() {
    this.#queue = [];
    this.#working = false;
  }
}
