import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { getErrorMessage } from "@/utils/get-error-message";
import ErrorPage from "../error-page";
import PageNotFound from "../page-not-found";

export function RouteErrorPage() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <PageNotFound />;
    }
  }

  return <ErrorPage message={getErrorMessage(error)} />;
}
