import * as React from "react";
import {
  Image,
  Anchor,
  Box,
  Button,
  Card,
  Group,
  Stack,
  Text,
  Title,
  Center,
  Flex,
} from "@mantine/core";
import { LogoTitle } from "@/components/logo-title";
import { Page } from "../_page";
import { AgeVerificationModal } from "../../components/age-verification-modal";
import {
  SignedIn,
  SignedOut,
  SignInButton,
  SignUpButton,
} from "@clerk/clerk-react";
import { Navigate } from "react-router-dom";
import landingPageScreenDesktopImage from "@/assets/landing-page-screen-desktop.png";
import landingPageScreenMobileImage from "@/assets/landing-page-screen-mobile.png";
import audioWaveImage from "@/assets/audio-wave.png";
import { avatars } from "@/assets/avatar/avatar-library";
import {
  IconPlayerPlayFilled,
  IconPlayerStopFilled,
} from "@tabler/icons-react";
import { useHover, useMediaQuery } from "@mantine/hooks";
import handySvg from "@/assets/handy.svg";
import lovensePng from "@/assets/lovense.png";
import scrolllerLogo from "@/assets/scrolller-text.png";

export function LandingPage() {
  const matches = useMediaQuery("(min-width: 800px)");

  return (
    <Page>
      <AgeVerificationModal />
      <LoginBar />
      <Center c="white">
        <Stack gap="xl" align="center" mt={matches ? 100 : 50}>
          <Box ta="center" maw={600}>
            <Title size="3.5rem" c="white" mb="xl" lh="4rem">
              The ultimate jerk-off experience
            </Title>
            <Text c="gray.5" mx="md">
              Fap Instructor is the ultimate jerk-off instruction experience.
              Fine tuned to your exact needs and wants.{" "}
              <Text span fw="bolder">
                Currently only available to{" "}
              </Text>
              <Anchor href="https://www.patreon.com/fapinstructor" fw="bolder">
                Patrons
              </Anchor>
            </Text>
          </Box>
          <Center>
            <FancySignupButton />
          </Center>
          <EpicScreenShot />
          <Stack gap="7rem" align="center">
            <ArtificalInstructorSection />
            <Group align="stretch" mx="md">
              <PornScrollerCard />
              <DeviceIntegrationCard />
            </Group>
          </Stack>
        </Stack>
      </Center>
      <TaskSection />
    </Page>
  );
}

function LoginBar() {
  return (
    <div style={{ position: "sticky", margin: "auto", maxWidth: 1000 }}>
      <Card radius="lg" withBorder m="md" p="xs" py="0">
        <Group justify="space-between">
          <Box ml="xs">
            <LogoTitle size="h3" c="white" bg="none" h={64} />
          </Box>
          <Group justify="end">
            <SignedIn>
              <Navigate to="/home" />
            </SignedIn>
            <SignedOut>
              <SignInButton>
                <Button variant="subtle">Log in</Button>
              </SignInButton>
            </SignedOut>
          </Group>
        </Group>
      </Card>
    </div>
  );
}

function EpicScreenShot() {
  const matches = useMediaQuery("(min-width: 800px)");

  return (
    <div
      style={{
        position: "relative",
        borderRadius: "1rem",
        backgroundImage:
          "linear-gradient(135deg, #ff4593, #ffe713 32%, #17d7ff 66%, #077bff)",
        padding: "1px",
        width: "fit-content",
        marginTop: matches ? "5rem" : "3rem",
        marginBottom: matches ? "7rem" : "6rem",
      }}
    >
      <Image
        src={
          matches ? landingPageScreenDesktopImage : landingPageScreenMobileImage
        }
        h={600}
        w="auto"
        style={{
          borderRadius: "1rem",
          backgroundImage:
            "linear-gradient(135deg, #ff4593, #ffe713 32%, #17d7ff 66%, #077bff)",
        }}
      />
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
          zIndex: -1,
          backgroundImage:
            "linear-gradient(135deg, #ff4593, #ffe713 33%, #17d7ff 66%, #077bff)",
          opacity: 0.3,
          filter: "blur(1.5rem)",
        }}
      />
    </div>
  );
}

function ArtificalInstructorSection() {
  const matches = useMediaQuery("(min-width: 800px)");
  const [activeVoice, setActiveVoice] =
    React.useState<keyof typeof avatars>("chloe");

  return (
    <Group gap="xl" align="flex-start" justify="center">
      <Card withBorder bg="dark.9" radius="1rem">
        <Group align="flex-end">
          <Box
            bg={avatars[activeVoice].gender === "female" ? "pink.1" : "blue.1"}
            p="xs"
            style={{ borderRadius: "1rem" }}
          >
            <Image src={avatars[activeVoice].image} h={100} w="auto" />
          </Box>
          {matches ? (
            <Stack justify="flex-end">
              <Image src={audioWaveImage} h="auto" w={300} />
            </Stack>
          ) : null}
        </Group>
      </Card>
      <Box ta={matches ? "left" : "center"}>
        <Title order={3}>AI-Generated Instructor Voices</Title>
        <Text size="sm" c="dimmed">
          Choose from a variety of different voices.
        </Text>
        <Flex
          mt="xs"
          gap="xs"
          maw={400}
          wrap="wrap"
          justify={matches ? "flex-start" : "center"}
        >
          <VoicePill
            voice="Chloe"
            audioSample="https://cdn.fapchallenger.com/chloe/8836382926607590534.mp3"
            onHover={() => {
              setActiveVoice("chloe");
            }}
          />
          <VoicePill
            voice="April"
            onHover={() => {
              setActiveVoice("april");
            }}
            audioSample="https://cdn.fapchallenger.com/april/14090898517933981890.mp3"
          />
          <VoicePill
            voice="Morrigan"
            onHover={() => {
              setActiveVoice("morrigan");
            }}
            audioSample="https://cdn.fapchallenger.com/morrigan/7426775831976815614.mp3"
          />
          <VoicePill
            voice="Betsy"
            onHover={() => {
              setActiveVoice("betsy");
            }}
            audioSample="https://cdn.fapchallenger.com/betsy/162786607951175441.mp3"
          />
          <VoicePill
            voice="Nora"
            onHover={() => {
              setActiveVoice("nora");
            }}
            audioSample="https://cdn.fapchallenger.com/nora/8273962623380601518.mp3"
          />
          <VoicePill
            voice="Luca"
            onHover={() => {
              setActiveVoice("luca");
            }}
            audioSample="https://cdn.fapchallenger.com/luca/8930569654368538159.mp3"
          />
          <VoicePill
            voice="Wyatt"
            onHover={() => {
              setActiveVoice("wyatt");
            }}
            audioSample="https://cdn.fapchallenger.com/wyatt/9148488496918535578.mp3"
          />
        </Flex>
      </Box>
    </Group>
  );
}

type VoicePillProps = {
  voice: string;
  audioSample: string;
  onHover: () => void;
};

function VoicePill({ voice, audioSample, onHover }: VoicePillProps) {
  const { hovered, ref } = useHover();
  // const audioRef = React.useRef(new Audio(audioSample));
  const audioRef = React.useRef<HTMLAudioElement>(null);
  const [playing, setPlaying] = React.useState(false);

  React.useEffect(() => {
    if (hovered) {
      onHover();
    }
  }, [hovered, onHover]);

  return (
    <Box ref={ref}>
      {/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
      <audio
        ref={audioRef}
        onPlaying={() => {
          setPlaying(true);
        }}
        onPause={() => {
          setPlaying(false);
        }}
        onEnded={() => {
          setPlaying(false);
        }}
        onError={() => {
          setPlaying(false);
        }}
      >
        <source src={audioSample} />
      </audio>
      <Button
        color="dark.5"
        radius="xl"
        onClick={() => {
          const audio = audioRef.current;
          if (!audio) return;

          if (audio.paused) {
            audio.play();
          } else {
            audio.pause();
            audio.currentTime = 0;
          }
        }}
      >
        <Group gap="0.25rem" h="100%">
          {playing ? (
            <IconPlayerStopFilled size="0.8rem" />
          ) : (
            <IconPlayerPlayFilled size="0.8rem" />
          )}
          <Text size="sm">{voice}</Text>
        </Group>
      </Button>
    </Box>
  );
}

type FeatureCardProps = {
  children: React.ReactNode;
};

function FeatureCard({ children }: FeatureCardProps) {
  return (
    <Card radius="1rem" withBorder bg="dark.9" maw={450}>
      <Stack align="center" p="xl">
        {children}
      </Stack>
    </Card>
  );
}

function DeviceIntegrationCard() {
  return (
    <FeatureCard>
      <Group gap="xs">
        <Card bg="dark.8" radius="1rem">
          <Image h={48} w="auto" src={handySvg} />
        </Card>
        <Card bg="dark.8" radius="1rem">
          <Image h={48} w="auto" src={lovensePng} />
        </Card>
      </Group>
      <Stack align="center" gap="xs">
        <Title order={3}>Toy Integration</Title>
        <Text size="sm" c="dimmed" ta="center" maw={300}>
          Seamless support for industry-leading sex toys like{" "}
          <Anchor
            href="https://www.thehandy.com/?ref=zjkwodh&utm_source=zjkwodh&utm_medium=affiliate&utm_campaign=The+Handy+Affiliate+program"
            c="blue"
          >
            TheHandy
          </Anchor>{" "}
          and{" "}
          <Anchor href="https://www.lovense.com/a/TheFapInstructor" c="blue">
            Lovense
          </Anchor>
          .
        </Text>
      </Stack>
    </FeatureCard>
  );
}

function PornScrollerCard() {
  return (
    <FeatureCard>
      <Box>
        <Image h={60} w="auto" src={scrolllerLogo} />
      </Box>
      <Stack align="center" gap="xs">
        <Title order={3}>Porn Scroller</Title>
        <Text size="sm" c="dimmed" ta="center" maw={300}>
          Dive into endless erotic content with our seamless porn scroller.{" "}
          <Text span c="gray.5">
            Or you can also use your own local files.
          </Text>
        </Text>
      </Stack>
    </FeatureCard>
  );
}

function TaskSection() {
  const matches = useMediaQuery("(min-width: 800px)");
  // const visibleTasks = matches ? tasks : tasks.slice(12);
  const visibleTasks = tasks;

  return (
    <Stack
      align="center"
      gap="xl"
      bg="radial-gradient(var(--mantine-color-dark-4), var(--mantine-color-body))"
      pb={100}
      px={1}
      mt="7rem"
    >
      <Flex
        gap={matches ? "md" : "xs"}
        wrap="wrap"
        justify="center"
        py={matches ? "4rem" : "1rem"}
        maw={1500}
      >
        {visibleTasks.map((task) => (
          <TaskPill key={task} name={task} />
        ))}
      </Flex>
      <Stack align="center" gap="xs">
        <Title>Fully Customizable</Title>
        <Text maw={600} ta="center" c="gray.5">
          Take complete control with a ridiculous amount of options, tasks, and
          configurations to guarantee each game is a completely fresh
          experience.
        </Text>
      </Stack>
      <FancySignupButton />
    </Stack>
  );
}

type TaskPillProps = {
  name: string;
};

function TaskPill({ name }: TaskPillProps) {
  const matches = useMediaQuery("(min-width: 800px)");

  return (
    <Card bg="dark.9" p="xs" radius="0">
      <Text size={matches ? "sm" : "xs"}>{name}</Text>
    </Card>
  );
}

const tasks = [
  "Gooning",
  "Orgasm Denial",
  "Bind Cock & Balls",
  "Hold Breath",
  "Pinch Clamps",
  "Attach Rubber Bands",
  "Post Orgasm Torment",
  "Grip Challenge",
  "CEI",
  "Stroke Style",
  "Teasing Stroke",
  "Edging",
  "Insert Buttplug",
  "Slap Balls",
  "Double Speed",
  "Inflatable Buttplug",
  "Flick Balls",
  "Warmup",
  "Icy Hot",
  "Clench",
  "Cluster Strokes",
  "Ruined Orgasms",
  "Censoring",
  "Nipple Clamps",
  "Vibrating Buttplug",
  "Palming",
  "Red Light, Green Light",
  "and so much more...",
];

function FancySignupButton() {
  return (
    <SignUpButton>
      <Button
        size="md"
        radius="lg"
        variant="gradient"
        gradient={{ from: "pink", to: "red", deg: 0 }}
      >
        Join now
      </Button>
    </SignUpButton>
  );
}
