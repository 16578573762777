import { Text, Box, Stack } from "@mantine/core";
import lovensePng from "@/assets/lovense.png";
import { CompanyTitle } from "../components/device-company-title";
import { BuyLovenseButton } from "./components/buy-lovense-button";
import { EstablishLovenseConnection } from "./components/establish-lovense-connection";
import { LovenseToyFinder } from "./components/lovense-toy-finder";
import { LovenseError } from "./components/lovense-error";
import { useLovense } from "@/api/lovense/use-lovense";
import { getErrorMessage } from "@/utils/get-error-message";

export function ConnectLovense() {
  const { qr, connected, error, toys } = useLovense();

  return (
    <Box>
      <Stack>
        <CompanyTitle name="Lovense" logo={lovensePng} />
        <BuyLovenseButton />
        {connected ? (
          <Text c="green">Connected to Lovense App</Text>
        ) : (
          <Text c="orange">Lovense Remote is not connected.</Text>
        )}
        {connected && !qr ? <LovenseToyFinder /> : null}
        {!connected || toys.length === 0 ? (
          <EstablishLovenseConnection />
        ) : null}
        {error ? (
          <LovenseError
            title="Unable to establish Lovense connection."
            message={getErrorMessage(error)}
          />
        ) : null}
      </Stack>
    </Box>
  );
}
