import type { AccountSettings } from "@fapinstructor/common";
import { Loader } from "@mantine/core";
import { ErrorBoundary } from "@sentry/react";
import * as React from "react";
import { useAccountSettings as useAccountSettingsQuery } from "@/api/fapinstructor/account/get-account-settings";
import { ErrorCard } from "@/components/error-card";
import { getErrorMessage } from "@/utils/get-error-message";

const AccountSettingsContext = React.createContext<AccountSettings | undefined>(
  undefined,
);

type AccountSettingsProviderProps = {
  children: React.ReactNode;
};

export function AccountSettingsProvider({
  children,
}: AccountSettingsProviderProps) {
  return (
    <ErrorBoundary
      fallback={(errorData) => (
        <ErrorCard
          title="Unable to retrieve account settings"
          message={getErrorMessage(errorData.error)}
        />
      )}
    >
      <_AccountSettingsProvider>{children}</_AccountSettingsProvider>
    </ErrorBoundary>
  );
}

function _AccountSettingsProvider({ children }: AccountSettingsProviderProps) {
  const accountSettingsQuery = useAccountSettingsQuery();

  if (!accountSettingsQuery.data) {
    return <Loader type="bars" />;
  }

  return (
    <AccountSettingsContext.Provider value={accountSettingsQuery.data}>
      {children}
    </AccountSettingsContext.Provider>
  );
}

export function useAccountSettings() {
  const context = React.useContext(AccountSettingsContext);
  if (context === undefined) {
    throw new Error(
      "useAccountSettings must be used within a AccountSettingsProvider ",
    );
  }
  return context;
}
