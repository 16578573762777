import {
  IconBattery,
  IconBattery1,
  IconBattery2,
  IconBattery3,
  IconBattery4,
} from "@tabler/icons-react";

type BatteryIconProps = {
  percentage: number;
};

export function BatteryIcon({ percentage }: BatteryIconProps) {
  if (percentage >= 90) {
    return <IconBattery4 />;
  }
  if (percentage >= 70) {
    return <IconBattery3 />;
  }
  if (percentage >= 40) {
    return <IconBattery2 />;
  }
  if (percentage >= 10) {
    return <IconBattery1 />;
  }

  return <IconBattery />;
}
