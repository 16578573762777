import { z } from "zod";
import { gameGeneratorFormSchema } from "./game-generator-form.js";
import { unsplashPhotoSchema } from "./unsplash.js";
export const GameGeneratorSchema = z.object({
    id: z.string(),
    createdBy: z.object({
        id: z.string(),
        username: z.string(),
    }),
    config: gameGeneratorFormSchema,
    createdAt: z.string(),
    updatedAt: z.string(),
    title: z.string(),
    description: z.string(),
    shared: z.boolean(),
    splash: unsplashPhotoSchema,
});
export const GameGeneratorsSchema = z.array(GameGeneratorSchema);
export const CreateGameGeneratorSchema = z.object({
    title: z.string().min(5).max(30),
    description: z.string().min(10).max(500),
    shared: z.boolean(),
    config: gameGeneratorFormSchema,
    splash: unsplashPhotoSchema,
});
export const UpdateGameGeneratorSchema = z.object({
    title: z.string().min(5).max(30).optional(),
    description: z.string().min(10).max(500).optional(),
    shared: z.boolean().optional(),
    config: gameGeneratorFormSchema.optional(),
    splash: unsplashPhotoSchema.optional(),
});
