import z from "zod";
export const GetLovenseTokenSchema = z.object({
    code: z.number(),
    message: z.string(),
    data: z
        .object({
        authToken: z.string(),
    })
        .optional(),
});
