import { Avatar, Group, Title, type TitleProps } from "@mantine/core";
import logoSvg from "@/assets/logo.svg";

type LogoTitleProps = TitleProps;

export function LogoTitle({
  size = "h4",
  bg = "white",
  h = 32,
  ...props
}: LogoTitleProps) {
  return (
    <Title size={size} {...props}>
      <Group>
        <Avatar src={logoSvg} alt="" bg={bg} h={h} /> Fap Instructor
      </Group>
    </Title>
  );
}
