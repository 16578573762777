import * as Mantine from "@mantine/core";
import * as Router from "react-router-dom";
import { navigation } from "@/utils/navigation";

type NavLinkProps = Mantine.NavLinkProps & Router.NavLinkProps;

/**
 * Wraps the Mantine NavLink component applying a custom active style
 * based off of react-routers resolved path.
 */
export function NavLink(props: NavLinkProps) {
  const resolved = Router.useResolvedPath(props.to);
  const match = Router.useMatch({ path: resolved.pathname, end: true });

  return (
    <Mantine.NavLink
      component={Router.Link}
      active={!!match}
      onClick={() => {
        navigation.opened = false;
      }}
      {...props}
    />
  );
}
