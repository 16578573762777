import * as Sentry from "@sentry/react";
import { ErrorCard } from "@/components/error-card";
import { isDevelopmentMode } from "@/utils/is-development-mode";
import { getErrorMessage } from "@/utils/get-error-message";

Sentry.init({
  enabled: !isDevelopmentMode(),
  dsn: "https://bee9fbf65aa4033763531d40d0115f73@o299453.ingest.sentry.io/4506513496473600",
  integrations: [
    // Sentry.captureConsoleIntegration(),
    Sentry.feedbackIntegration({
      buttonLabel: "",
      colorScheme: "dark",
    }),
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  tracesSampleRate: 0.1,
  tracePropagationTargets: [
    /^http:\/\/localhost/,
    /^https:\/\/api\.fapchallenger.com/,
  ],
  profilesSampleRate: 0.1,
  // replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0.1,
});

type SentryProviderProps = {
  children: React.ReactNode;
};

export function SentryProvider({ children }: SentryProviderProps) {
  return (
    <Sentry.ErrorBoundary
      fallback={(errorData) => (
        <ErrorCard
          title="An unexpected error occured"
          message={getErrorMessage(errorData.error)}
        />
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
}
