import { findPercentageInRange, findValueInRangeByPercentage } from "./math.js";
export function getTempoFromPercent([minTempo, maxTempo], tempoPercent) {
    // Convert the stroke tempo percent to an integer.
    const tempo = Math.round(findValueInRangeByPercentage(minTempo, maxTempo, tempoPercent));
    return tempo;
}
export function getPercentFromTempo([minTempo, maxTempo], tempo) {
    // Convert the tempo integer to a percentage.
    return Math.round(findPercentageInRange(minTempo, maxTempo, tempo));
}
