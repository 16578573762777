import { getTempoFromPercent } from "@fapinstructor/math";
import { toyActionsValueRanges, } from "../constants/device-info.js";
export function getActionValue(percentage, action) {
    const range = toyActionsValueRanges[action];
    let actionValue = getTempoFromPercent(range, percentage);
    // If the percentage is too low, the value will be normalized to 0.
    // We hard-code the value to the lowest range so at least the device is on.
    if (percentage > 0 && actionValue === 0) {
        actionValue = 1;
    }
    return actionValue;
}
