import { GetLovenseTokenSchema } from "@fapinstructor/common";
import {
  fapinstructorApi,
  handleFapInstructorResponseApiError,
} from "../fapinstructor-api";
import { useQuery } from "@tanstack/react-query";

export async function getLovenseToken() {
  const response = await fapinstructorApi
    .get("/lovense/token")
    .catch(handleFapInstructorResponseApiError);

  const { data } = GetLovenseTokenSchema.parse(response.data);

  if (!data?.authToken) {
    throw new Error("Failed to retrieve lovense token.");
  }

  return data.authToken;
}

export function useLovenseToken() {
  const query = useQuery({
    queryKey: ["lovense", "token"],
    queryFn: getLovenseToken,
  });

  return query;
}
