import * as Clerk from "@clerk/clerk-react";
import { neobrutalism } from "@clerk/themes";
import "@mantine/core/styles.css";
import "@mantine/notifications/styles.css";
import * as Sentry from "@sentry/react";
// import flagsmith from "flagsmith";
import type React from "react";
import { FapInstructorAPIAuthInject } from "@/api/fapinstructor/fapinstructor-api";
import { CLERK_PUBLISHABLE_KEY } from "@/config";
import { router } from "@/routes";

type ClerkProviderWrapperProps = {
  children: React.ReactNode;
};

export function ClerkProvider({ children }: ClerkProviderWrapperProps) {
  return (
    <Clerk.ClerkProvider
      publishableKey={CLERK_PUBLISHABLE_KEY}
      routerPush={(to) => router.navigate(to)}
      routerReplace={(to) => router.navigate(to, { replace: true })}
      signInUrl="/sign-in"
      signUpUrl="/sign-up"
      appearance={{
        baseTheme: neobrutalism,
      }}
    >
      <Clerk.ClerkLoaded>
        <AttachUserToSentry />
        <FapInstructorAPIAuthInject />
        {children}
      </Clerk.ClerkLoaded>
    </Clerk.ClerkProvider>
  );
}

function AttachUserToSentry() {
  const { isSignedIn, userId } = Clerk.useAuth();

  if (isSignedIn) {
    Sentry.setUser({ id: userId });
    // flagsmith.identify(userId);
  }

  return null;
}
