import { Button, Center, Group, Stack } from "@mantine/core";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ErrorCard } from "@/components/error-card";
import { Page } from "../_page";

type ErrorPageProps = {
  message?: string;
};

export function ErrorPage(props: ErrorPageProps) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const title = searchParams.get("title") ?? "Unexpected Error";
  const message =
    props.message ??
    searchParams.get("message") ??
    "An unknown error has occured.";

  return (
    <Page title="Error">
      <Center h="100%">
        <Stack>
          <ErrorCard title={title} message={message} />
          <Center>
            <Group>
              <Button
                onClick={() => {
                  navigate("/");
                }}
              >
                Go Home
              </Button>
              <Button
                variant="light"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Try again
              </Button>
            </Group>
          </Center>
        </Stack>
      </Center>
    </Page>
  );
}
