import { LoadingOverlay } from "@mantine/core";
import * as React from "react";
import * as Router from "react-router-dom";

export default function FocusLayout() {
  return (
    <React.Suspense fallback={<DelayedLoadingOverlay />}>
      <Router.Outlet />
    </React.Suspense>
  );
}

function DelayedLoadingOverlay() {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  if (!show) {
    return null;
  }

  return (
    <LoadingOverlay
      visible={true}
      zIndex={1000}
      loaderProps={{ color: "pink", type: "bars" }}
    />
  );
}
