import { Button, Image, Stack, Text, Center } from "@mantine/core";
import { lovense, useLovense } from "@/api/lovense/use-lovense";
import type { QrCode } from "@fapinstructor/lovense";

export function EstablishLovenseConnection() {
  const { qr } = useLovense();

  return qr ? <ScaneQrCode qr={qr} /> : <GenerateQrCodeButton />;
}

type ScaneQrCodeProps = {
  qr: QrCode;
};

function ScaneQrCode({ qr }: ScaneQrCodeProps) {
  return (
    <Stack gap="xs">
      <Text size="sm">Scan the QR code below using your Lovense Remote</Text>
      <Image radius="md" src={qr.qrcodeUrl} />
      <Center>
        <Text size="sm">
          Connect Code: <b>{qr.code}</b>
        </Text>
      </Center>
    </Stack>
  );
}

function GenerateQrCodeButton() {
  return (
    <Button
      onClick={() => {
        lovense.generateQrCode();
      }}
    >
      Generate QR Code
    </Button>
  );
}
