import type * as React from "react";
import * as Mantine from "@mantine/core";
import "@mantine/core/styles.css";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import "@mantine/notifications/styles.css";

type MantineProviderProps = {
  children: React.ReactElement;
};

export function MantineProvider({ children }: MantineProviderProps) {
  return (
    <Mantine.MantineProvider defaultColorScheme="dark">
      <ModalsProvider>
        <Notifications position="top-center" />
        {children}
      </ModalsProvider>
    </Mantine.MantineProvider>
  );
}
