import z from "zod";
export const toyActions = z.enum([
    "Vibrate",
    "Rotate",
    "Pump",
    "Thrusting",
    "Fingering",
    "Suction",
    "Depth",
    "All",
]);
export const toyActionsValueRanges = {
    Vibrate: [0, 20],
    Rotate: [0, 20],
    Pump: [0, 3],
    Thrusting: [0, 20],
    Fingering: [0, 20],
    Suction: [0, 20],
    Depth: [0, 3],
    All: [0, 20],
};
export const SupportedToysSchema = z.enum([
    "edge",
    "ridge",
    "hush",
    "max",
    "solace",
    "calor",
    "gush",
    "solace pro",
    // random devices that aren't fully supported yet.
    "gravity",
    "gemini",
    "flexer",
    "exomoon",
    "xmachine",
    "hyphy",
    "dolce",
    "lush",
    "diamo",
    "ferri",
    "domi",
    "osci",
    "nora",
    "ambi",
    "tenera",
    "vulse",
    "lapis",
    "mission",
]);
export const supportedToys = SupportedToysSchema.options;
export const deviceFunctions = {
    edge: ["Vibrate"],
    ridge: ["Vibrate", "Rotate"],
    hush: ["Vibrate"],
    max: ["Vibrate", "Pump"],
    solace: ["Thrusting", "Depth"],
    "solace pro": ["Thrusting", "Depth"], // Position
    calor: ["Vibrate"],
    gush: ["Vibrate"],
    // Unsupported devices that are attached to the stroker category just for basic functionality.
    gravity: ["All"],
    gemini: ["All"],
    flexer: ["All"],
    exomoon: ["All"],
    xmachine: ["All"],
    hyphy: ["All"],
    dolce: ["All"],
    lush: ["All"],
    diamo: ["All"],
    ferri: ["All"],
    domi: ["All"],
    osci: ["All"],
    nora: ["All"],
    ambi: ["All"],
    tenera: ["All"],
    vulse: ["All"],
    lapis: ["All"],
    mission: ["All"],
};
export const toyType = {
    anal: ["edge", "ridge", "hush"],
    stroker: [
        "max",
        "solace",
        "solace pro",
        "calor",
        "gush",
        // Unsupported devices that are attached to the stroker category just for basic functionality.
        "gravity",
        "gemini",
        "flexer",
        "exomoon",
        "xmachine",
        "hyphy",
        "dolce",
        "lush",
        "diamo",
        "ferri",
        "domi",
        "osci",
        "nora",
        "ambi",
        "tenera",
        "vulse",
        "lapis",
        "mission",
    ],
    //   vibrator: [],
    //   dildo: [],
    //   nipples: [],
};
