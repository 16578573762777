import { Group, Text } from "@mantine/core";
import { BatteryIcon } from "@/components/battery-icon";
import type * as Lovense from "@fapinstructor/lovense";
import { supportedToys } from "@fapinstructor/lovense";

type LovenseToyProps = Lovense.LovenseToy;

export function LovenseToy({
  toyType,
  name,
  battery,
  connected,
}: LovenseToyProps) {
  const isSupported = (supportedToys as string[]).includes(toyType);

  return (
    <Group justify="space-between">
      <Group gap="xs" align="flex-start">
        <BatteryIcon percentage={battery} />
        <Text>{capitalize(name)}</Text>
      </Group>
      {connected ? (
        !isSupported ? (
          <Text c="orange">Unsupported</Text>
        ) : (
          <Text c="green">Connected</Text>
        )
      ) : (
        <Text c="orange">Disconnected</Text>
      )}
    </Group>
  );
}

function capitalize(str: string) {
  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}
