import { AppShell, Divider } from "@mantine/core";
import {
  IconActivity,
  IconBookmark,
  IconCompass,
  IconDice2,
  IconHammer,
  IconHome2,
  IconSettings,
  IconTestPipe,
} from "@tabler/icons-react";
import { isDevelopmentMode } from "@/utils/is-development-mode";
import { NavLink } from "./navlink";

export function Navigation() {
  return (
    <>
      <AppShell.Section>
        <AppShell.Section mt="sm">
          <NavLink to="/home" label="Home" leftSection={<IconHome2 />} />
        </AppShell.Section>
        <Divider mx="lg" my="sm" />
        <AppShell.Section>
          <NavLink
            to="game-generator"
            label="Game Generator"
            leftSection={<IconDice2 />}
          />
          <NavLink
            to="my-game-generators"
            label="My Game Generators"
            leftSection={<IconBookmark />}
          />
          <NavLink
            to="explore-game-generators"
            label="Explore Game Generators"
            leftSection={<IconCompass />}
          />
        </AppShell.Section>
        <Divider mx="lg" my="sm" />
        <AppShell.Section>
          <NavLink
            to="game-builder"
            label="Game Builder"
            leftSection={<IconHammer />}
          />
          <NavLink
            to="my-games"
            label="My Games"
            leftSection={<IconBookmark />}
          />
          <NavLink
            to="explore-games"
            label="Explore Games"
            leftSection={<IconCompass />}
          />
        </AppShell.Section>
      </AppShell.Section>
      <Divider mx="lg" my="sm" />
      <AppShell.Section>
        <NavLink
          to="settings"
          label="Settings"
          leftSection={<IconSettings />}
        />
      </AppShell.Section>
      {isDevelopmentMode() ? (
        <>
          <Divider />
          <NavLink
            to="metronome"
            label="Metronome"
            leftSection={<IconActivity />}
          />
          <NavLink
            to="hud"
            label="HUD Testing"
            leftSection={<IconTestPipe />}
          />
        </>
      ) : null}
    </>
  );
}
