import { Group, Image, Title } from "@mantine/core";

type CompanyTitleProps = {
  name: string;
  logo: string;
};

export function CompanyTitle({ name, logo }: CompanyTitleProps) {
  return (
    <Group gap="xs">
      <Image h={25} src={logo} />
      <Title order={2}>{name}</Title>
    </Group>
  );
}
