import { accountSettingsSchema } from "@fapinstructor/common";
import { useQuery } from "@tanstack/react-query";
import {
  fapinstructorApi,
  handleFapInstructorResponseApiError,
} from "../fapinstructor-api";

async function getAccountSettings() {
  const response = await fapinstructorApi
    .get("/account/settings")
    .catch(handleFapInstructorResponseApiError);

  const settings = accountSettingsSchema.parse(response.data);
  return settings;
}

export function useAccountSettings() {
  const query = useQuery({
    queryKey: ["account", "settings"],
    queryFn: getAccountSettings,
    staleTime: 60 * 60 * 1000, // 1 hour
    throwOnError: true,
  });

  return query;
}
