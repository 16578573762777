import { Button } from "@mantine/core";

type BuyDeviceButtonProps = { url: string };

export function BuyDeviceButton({ url }: BuyDeviceButtonProps) {
  return (
    <Button
      component="a"
      color="green"
      variant="light"
      target="_blank"
      href={url}
    >
      Buy Now
    </Button>
  );
}
