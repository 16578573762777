import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React from "react";
import ReactDOM from "react-dom/client";
import "./main.css";
// import { FlagsmithProvider } from "./providers/flagsmith-provider";
import { ClerkProvider } from "./providers/clerk-provider";
import { QueryClientProvider } from "./providers/query-client-provider";
import { MantineProvider } from "./providers/mantine-provider";
import { SentryProvider } from "./providers/sentry-provider";
import { RouterProvider } from "./routes";

/*
 * When deploying a new app the assets have difference hashes in the file names.
 * This watches for when a dynamic import fails to load and if so refreshes the page.
 */
window.addEventListener("vite:preloadError", (event) => {
  window.location.reload();
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <SentryProvider>
      {/* <FlagsmithProvider> */}
      <MantineProvider>
        <QueryClientProvider>
          <ReactQueryDevtools initialIsOpen={false} />
          <ClerkProvider>
            <RouterProvider />
          </ClerkProvider>
        </QueryClientProvider>
      </MantineProvider>
      {/* </FlagsmithProvider> */}
    </SentryProvider>
  </React.StrictMode>,
);
