import { z } from "zod";
export const LovenseSocketUrlSchema = z.object({
    code: z.number(),
    message: z.string(),
    data: z
        .object({
        socketIoPath: z.string(),
        socketIoUrl: z.string(),
    })
        .optional(),
});
