import { useAuth } from "@clerk/clerk-react";
import Axios from "axios";
import * as React from "react";
import { z } from "zod";
import { FAPINSTRUCTOR_API } from "@/config";

export const fapinstructorApi = Axios.create({
  baseURL: FAPINSTRUCTOR_API,
});

fapinstructorApi.interceptors.request.use(async (config) => {
  if (!tokenHandler.getToken) {
    return config;
  }

  const token = await tokenHandler.getToken();
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

class TokenHandler {
  getToken?: () => Promise<string | null>;

  setTokenHandler(getToken: TokenHandler["getToken"]) {
    this.getToken = getToken;
  }
}

export const tokenHandler = new TokenHandler();

export function FapInstructorAPIAuthInject() {
  const { getToken } = useAuth();

  React.useEffect(() => {
    tokenHandler.setTokenHandler(async () => {
      const token = await getToken();
      return token;
    });
    return () => {
      tokenHandler.setTokenHandler(undefined);
    };
  }, [getToken]);

  return null;
}

const fapInstructorApiResponseErrorSchema = z.object({
  error: z.string(),
  message: z.string(),
  statusCode: z.number(),
});

/**
 * Extract the error message from the response body from an `AxiosError`.
 */
// biome-ignore lint/suspicious/noExplicitAny:
export function handleFapInstructorResponseApiError(error: any): never {
  // Either the request failed or setting up the request failed.
  if (!error.response) {
    throw error;
  }

  // The response was successful but returned an out of range 2xx status code.
  const errorData = fapInstructorApiResponseErrorSchema.parse(
    error.response.data,
  );
  throw new Error(errorData.message);
}
