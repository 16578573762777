import z from "zod";
import { gameGeneratorFormSchema } from "./game-generator-form.js";
export const accountSettingsSchema = z.object({
    tempo: z
        .tuple([z.number().min(0), z.number().min(0)])
        .refine((tempo) => tempo[0] < tempo[1], {
        message: "Max tempo cannot be less than min tempo",
    }),
    edgingTempoPercent: z.number().min(0).max(100),
    chat: z.object({
        speed: z.number(),
    }),
    gameGeneratorConfig: gameGeneratorFormSchema,
});
