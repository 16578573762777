import { LoadingOverlay } from "@mantine/core";
import { Navigate } from "react-router-dom";
import { usePatronStatus } from "@/api/fapinstructor/patreon/get-patreon-status";
import { getErrorMessage } from "@/utils/get-error-message";

type RedirectNonPatronsProps = {
  children: React.ReactNode;
};

export function RedirectNonPatrons({ children }: RedirectNonPatronsProps) {
  const patronStatusQuery = usePatronStatus();

  if (patronStatusQuery.isFetching) {
    return (
      <LoadingOverlay
        visible={true}
        zIndex={1000}
        overlayProps={{ opacity: 1, blur: 1 }}
        loaderProps={{ color: "pink", type: "bars" }}
      />
    );
  }

  if (patronStatusQuery.isError) {
    return (
      <Navigate
        to={`/error?title=Unable to determine patron status&message=${getErrorMessage(patronStatusQuery.error)}`}
      />
    );
  }

  if (!patronStatusQuery.data?.subscribed) {
    return <Navigate to="/patreon/signup" />;
  }

  return <>{children}</>;
}
