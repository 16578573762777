import * as React from "react";
import { proxy, useSnapshot } from "valtio";
import { Lovense, type LovenseToy, type QrCode } from "@fapinstructor/lovense";
import { useLovenseToken } from "../fapinstructor/lovense/get-token";

const lovenseStore: {
  connected: boolean;
  toys: LovenseToy[];
  qr?: QrCode;
  error?: unknown;
} = proxy({
  connected: false,
  error: undefined,
  toys: [],
  qr: undefined,
});

export const lovense = new Lovense();

export function useLovense() {
  const tokenQuery = useLovenseToken();
  const lovenseSnap = useSnapshot(lovenseStore);

  React.useEffect(() => {
    if (!tokenQuery.data) {
      return;
    }

    const unsubscribeQrListener = lovense.onQrCode.subscribe((qr) => {
      lovenseStore.qr = qr;
    });

    const unsubscribeToyListener = lovense.onToy.subscribe((toys) => {
      lovenseStore.toys = toys;
    });

    const unsubscribeStatusListener = lovense.onStatus.subscribe(
      (connected) => {
        lovenseStore.connected = connected;
      },
    );

    const unsubscribeErrorListener = lovense.onError.subscribe((error) => {
      lovenseStore.error = error;
    });

    lovense.connect(tokenQuery.data);

    return () => {
      unsubscribeQrListener();
      unsubscribeToyListener();
      unsubscribeStatusListener();
      unsubscribeErrorListener();
    };
  }, [tokenQuery.data]);

  return lovenseSnap;
}
