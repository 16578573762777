import { Button, Stack, Text, TextInput, RangeSlider } from "@mantine/core";
import { useForm, zodResolver } from "@mantine/form";
import * as React from "react";
import { useSnapshot } from "valtio/react";
import { z } from "zod";
import { handy } from "@/api/handy";
import { connectHandy, handyState } from "@/api/handy/handy";
import handySvg from "@/assets/handy.svg";
import { CompanyTitle as DeviceCompanyTitle } from "../components/device-company-title";
import { BuyHandyButton } from "./components/buy-handy-button";
import { handySettings } from "@/api/handy/store/handy-settings";
import { useDebouncedCallback } from "@mantine/hooks";

const connectHandySchema = z.object({
  connectionKey: z
    .string()
    .min(1, { message: "Please enter a connection key." }),
});
type ConnectHandySchema = z.infer<typeof connectHandySchema>;

export function ConnectHandy() {
  const { current: handyStateSnap } = useSnapshot(handyState);
  const handySettingsSnap = useSnapshot(handySettings);
  const connected = Boolean(handyStateSnap?.connected);
  const connecting = handyStateSnap?.connecting;
  const error = handyStateSnap?.error;

  const form = useForm<ConnectHandySchema>({
    validate: zodResolver(connectHandySchema),
    initialValues: {
      connectionKey: "",
    },
  });

  const onSlideChange = useDebouncedCallback(
    async ([min, max]: [min: number, max: number]) => {
      handy.setStrokeZone({ min, max });
    },
    1_000,
  );

  // Load the connection key from storage into component state.
  React.useEffect(() => {
    if (!form.values.connectionKey) {
      handy.getStoredKey().then((storedConnectionKey) => {
        if (storedConnectionKey) {
          form.setFieldValue("connectionKey", storedConnectionKey);
        }
      });
    }
  }, [form.setFieldValue, form.values.connectionKey]);

  return (
    <form
      onSubmit={form.onSubmit(async ({ connectionKey }) => {
        if (connected) {
          await handy.disconnect();
          form.reset();
        } else {
          await connectHandy(connectionKey);
        }
      })}
    >
      <Stack>
        <DeviceCompanyTitle name="TheHandy" logo={handySvg} />
        {!connected && !error ? <BuyHandyButton /> : null}
        {connected ? (
          <Text c="green">Device connected</Text>
        ) : error ? (
          <Text c="red">Failed to connect to device.</Text>
        ) : (
          <Text c="orange">TheHandy is not connected.</Text>
        )}
        {connected ? (
          <StrokeAdjustment
            value={handySettingsSnap.slide}
            onChange={(value) => {
              handySettings.slide = value;
              onSlideChange(value);
            }}
          />
        ) : null}
        <TextInput
          // disabled={connected && !error}
          placeholder="Enter connection key"
          {...form.getInputProps("connectionKey")}
        />
        <Button loading={connecting} type="submit">
          {connected ? "Disconnect" : "Connect"}
        </Button>
      </Stack>
    </form>
  );
}

type StrokeAdjustmentProps = {
  value: [min: number, max: number];
  onChange: (value: [min: number, max: number]) => void;
};

function StrokeAdjustment({ value, onChange }: StrokeAdjustmentProps) {
  return (
    <Stack>
      <Text>Stroke Adjustment</Text>
      <RangeSlider
        mb="md"
        value={value}
        onChange={onChange}
        minRange={0}
        maxRange={100}
        marks={[
          { value: 0, label: "0" },
          { value: 20, label: "20" },
          { value: 40, label: "40" },
          { value: 60, label: "60" },
          { value: 80, label: "80" },
          { value: 100, label: "100" },
        ]}
      />
    </Stack>
  );
}
