import * as React from "react";

type PageProps = {
  title?: string;
  children: React.ReactNode;
};

const appName = "Fap Instructor";

export function Page({ children, title }: PageProps) {
  React.useEffect(() => {
    document.title = title ? `${title} | ${appName}` : appName;
  }, [title]);

  return <>{children}</>;
}
