import { z } from "zod";
import { handleSecurityError } from "@/store/handle-security-error";
import { proxy, subscribe } from "valtio";

const HandyStoreSchema = z.object({
  version: z.number(),
  slide: z.tuple([z.number().min(0).max(100), z.number().min(0).max(100)]),
});

type HandyStore = z.infer<typeof HandyStoreSchema>;

const initialHandyStoreState: HandyStore = {
  version: 1,
  slide: [0, 100],
};

const HANDY_LOCAL_STORAGE_ID = "handy-settings";

function loadHandySettings() {
  let settings = initialHandyStoreState;

  try {
    const localStorageSettings = localStorage.getItem(HANDY_LOCAL_STORAGE_ID);

    if (localStorageSettings) {
      const handyStore: HandyStore = JSON.parse(localStorageSettings);
      settings = handyStore;
    }
  } catch (error) {
    handleSecurityError(error);
  }
  return settings;
}

export const handySettings = proxy(loadHandySettings());

subscribe(handySettings, () => {
  try {
    localStorage.setItem(HANDY_LOCAL_STORAGE_ID, JSON.stringify(handySettings));
  } catch (error) {
    handleSecurityError(error);
  }
});
