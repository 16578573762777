import { Text, Box, Title } from "@mantine/core";

type LovenseErrorProps = {
  title: string;
  message: string;
};

export function LovenseError({ title, message }: LovenseErrorProps) {
  return (
    <Box>
      <Title c="red" order={4}>
        {title}
      </Title>
      <Text size="sm">{message}</Text>
    </Box>
  );
}
