import { z } from "zod";
export const unsplashPhotoSchema = z.object({
    id: z.string(),
    photo: z.string().min(1, { message: "Please select a photo" }),
    downloadLink: z.string(),
    author: z.object({
        name: z.string(),
        profile: z.string(),
    }),
});
export const unsplashResponseSchema = z.object({
    data: z.array(unsplashPhotoSchema),
    pagination: z.object({
        total: z.number(),
        next: z.number().nullable(),
    }),
});
