import { z } from "zod";
export const voices = z.enum([
    "chloe",
    "april",
    "kanako",
    "morrigan",
    "luca",
    "wyatt",
    "betsy",
    "nora",
]);
export const speechRequestParams = z.object({
    voice: voices,
});
export const speechRequestBody = z.object({ text: z.string().min(1).max(500) });
export const speechResponse = z.object({ id: z.string(), speech: z.string() });
