import { Stack, Title } from "@mantine/core";
import type * as Lovense from "@fapinstructor/lovense";
import { LovenseToy } from "./lovense-toy";
import { LovenseError } from "./lovense-error";
import { useLovense } from "@/api/lovense/use-lovense";

export function LovenseToyFinder() {
  const { toys } = useLovense();

  if (!toys || toys.length === 0) {
    return <NoToysFound />;
  }

  return <ConnectedToys toys={toys} />;
}

type ConnectedToysProps = {
  toys: Lovense.LovenseToy[];
};

function ConnectedToys({ toys }: ConnectedToysProps) {
  return (
    <Stack>
      <Title order={3}>Devices</Title>
      {toys.map((toy) => (
        <LovenseToy key={toy.id} {...toy} />
      ))}
    </Stack>
  );
}

function NoToysFound() {
  return (
    <LovenseError
      title="No toys were found."
      message="Try checking your Lovense app and ensure your toys have been connected."
    />
  );
}
