import { lazy } from "react";
import * as ReactRouter from "react-router-dom";
import FocusLayout from "./pages/_layouts/focus-layout";
import MainLayout from "./pages/_layouts/main-layout";
import ErrorPage from "./pages/error-page";
import RouteErrorPage from "./pages/route-error-page";
import { PrivateRoute } from "./components/private-route";
import LandingPage from "./pages/landing-page";

const ExploreGamesPage = lazy(() => import("./pages/explore-games-page"));
const MyGameGeneratorsPage = lazy(
  () => import("./pages/my-game-generators-page"),
);
const ExploreGameGeneratorsPage = lazy(
  () => import("./pages/explore-game-generators-page"),
);
const GameBuilderPage = lazy(() => import("./pages/game-builder-page"));
const GameGeneratorPage = lazy(() => import("./pages/game-generator-page"));
const GamePage = lazy(() => import("./pages/game-page"));
const HUDTestingPage = lazy(() => import("./pages/hud-testing-page"));
const HomePage = lazy(() => import("./pages/home-page"));
const MetronomePage = lazy(() => import("./pages/metronome-page"));
const MyGamesPage = lazy(() => import("./pages/my-games-page"));
const PatreonPage = lazy(() => import("./pages/patreon-page"));
const PatreonSignupPage = lazy(() => import("./pages/patreon-signup-page"));
const SettingsPage = lazy(() => import("./pages/settings-page"));
const SignInPage = lazy(() => import("./pages/sign-in-page"));
const SignUpPage = lazy(() => import("./pages/sign-up-page"));

export const router = ReactRouter.createBrowserRouter([
  // Public pages.
  {
    path: "/",
    element: <FocusLayout />,
    errorElement: <RouteErrorPage />,
    children: [
      {
        path: "",
        index: true,
        element: <LandingPage />,
      },
      {
        path: "error",
        element: <ErrorPage />,
      },
      {
        path: "sign-in/*",
        element: <SignInPage />,
      },
      {
        path: "sign-up/*",
        element: <SignUpPage />,
      },
    ],
  },
  // Protected pages that DOES require a Patreon subscription.
  {
    path: "/",
    element: <PrivateRoute />,
    errorElement: <RouteErrorPage />,
    children: [
      {
        path: "",
        element: <MainLayout />,
        children: [
          {
            path: "home",
            element: <HomePage />,
          },
          {
            path: "my-games",
            element: <MyGamesPage />,
          },
          {
            path: "explore-games",
            element: <ExploreGamesPage />,
          },
          {
            path: "metronome",
            element: <MetronomePage />,
          },
          {
            path: "game-builder/:id?",
            element: <GameBuilderPage />,
          },
          {
            path: "game-generator/:id?",
            element: <GameGeneratorPage />,
          },
          {
            path: "my-game-generators",
            element: <MyGameGeneratorsPage />,
          },
          {
            path: "explore-game-generators",
            element: <ExploreGameGeneratorsPage />,
          },
          {
            path: "settings",
            element: <SettingsPage />,
          },
        ],
      },
      {
        path: "",
        element: <FocusLayout />,
        children: [
          {
            path: "hud",
            element: <HUDTestingPage />,
          },
          {
            path: "game",
            element: <GamePage />,
          },
        ],
      },
    ],
  },
  // Protected pages that DO NOT require a Patreon subscription.
  {
    path: "/",
    element: <PrivateRoute requireSubscription={false} />,
    errorElement: <RouteErrorPage />,
    children: [
      {
        path: "patreon",
        element: <FocusLayout />,
        children: [
          {
            path: "",
            element: <PatreonPage />,
          },
          {
            path: "signup",
            element: <PatreonSignupPage />,
          },
        ],
      },
    ],
  },
]);

export function RouterProvider() {
  return <ReactRouter.RouterProvider router={router} />;
}
