import type { Voice } from "@fapinstructor/common";
import aprilAvatar from "./april.svg";
import chloeAvatar from "./chloe.svg";
import lucaAvatar from "./luca.svg";
import kanakoAvatar from "./kanako.svg";
import morriganAvatar from "./morrigan.svg";
import betsyAvatar from "./betsy.svg";
import noraAvatar from "./nora.svg";
import wyattAvatar from "./wyatt.svg";

type Gender = "male" | "female";

type Avatar = {
  name: string;
  description: string;
  image: string;
  gender: Gender;
};

export const avatars: Record<Voice, Avatar> = {
  chloe: {
    name: "Chloe",
    description:
      "A sophisticated British mistress that will put you in your place.",
    image: chloeAvatar,
    gender: "female",
  },
  april: {
    name: "April",
    description: "An all American girl ready to make you hers.",
    image: aprilAvatar,
    gender: "female",
  },
  kanako: {
    name: "Kanako",
    description:
      "Kanako is a smart, busty college student ready to make your waifu dreams come true.",
    image: kanakoAvatar,
    gender: "female",
  },
  morrigan: {
    name: "Morrigan",
    description:
      "A seductive and dangerous femme fatale with sexy allure and villainistic tendencies.",
    image: morriganAvatar,
    gender: "female",
  },
  betsy: {
    name: "Betsy",
    description:
      "Insane, unpredictable, and unhinged. Maybe she shouldn't have escaped the psych ward?",
    image: betsyAvatar,
    gender: "female",
  },
  nora: {
    name: "Nora",
    description:
      "A hot mature teacher that has taken a keen eye to you. You better pay attention; you might be tested.",
    image: noraAvatar,
    gender: "female",
  },
  luca: {
    name: "Luca",
    description: "A powerful business man that will take control.",
    image: lucaAvatar,
    gender: "male",
  },
  wyatt: {
    name: "Wyatt",
    description: "A cowboy from the west who's ready to lasso you up.",
    image: wyattAvatar,
    gender: "male",
  },
};
