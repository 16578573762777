import { isPatreonSubscriberResponse } from "@fapinstructor/common";
import { useQuery } from "@tanstack/react-query";
import {
  fapinstructorApi,
  handleFapInstructorResponseApiError,
} from "../fapinstructor-api";

async function getPatreonStatus() {
  const response = await fapinstructorApi
    .get("/patreon/status")
    .catch(handleFapInstructorResponseApiError);

  const patreonStatus = isPatreonSubscriberResponse.parse(response.data);
  return patreonStatus;
}

export function usePatronStatus() {
  const query = useQuery({
    queryKey: ["patreon", "status"],
    queryFn: getPatreonStatus,
    staleTime: PATREON_STATUS_STALE_TIME,
  });

  return query;
}

const PATREON_STATUS_STALE_TIME =
  20 * // min
  60 * // sec
  1000; // ms
