import { UserButton } from "@clerk/clerk-react";
import { AppShell, Box, Burger, Flex, Group, Loader } from "@mantine/core";
import * as React from "react";
import * as Router from "react-router-dom";
import { useSnapshot } from "valtio";
import { ConnectDeviceModal } from "@/components/integrated-devices/connect-device-modal";
import { LogoTitle } from "@/components/logo-title";
import { Navigation } from "@/components/navigation";
import { navigation } from "@/utils/navigation";

export default function MainLayout() {
  const { opened } = useSnapshot(navigation);

  return (
    <AppShell
      header={{ height: 60 }}
      navbar={{
        width: 250,
        breakpoint: "sm",
        collapsed: { mobile: !opened },
      }}
    >
      <AppShell.Header p="xs">
        <Group justify="space-between">
          <Burger
            opened={opened}
            onClick={() => {
              navigation.opened = !opened;
            }}
            hiddenFrom="sm"
            size="sm"
          />
          <LogoTitle />
          <Group>
            <ConnectDeviceModal size="button" />
            <UserButton />
          </Group>
        </Group>
      </AppShell.Header>
      <AppShell.Navbar>
        <Navigation />
      </AppShell.Navbar>
      <AppShell.Main>
        <React.Suspense fallback={<DelayedLoader />}>
          <Box p="lg">
            <Router.Outlet />
          </Box>
        </React.Suspense>
      </AppShell.Main>
    </AppShell>
  );
}

function DelayedLoader() {
  const [show, setShow] = React.useState(false);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setShow(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  if (!show) {
    return null;
  }

  return (
    <Flex justify="center" align="center" pt="10vh">
      <Loader color="pink" />
    </Flex>
  );
}
