import {
  Anchor,
  Button,
  Center,
  Group,
  Modal,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { useLocalStorage } from "@mantine/hooks";
import { LogoTitle } from "@/components/logo-title";

export function AgeVerificationModal() {
  const [verifiedAge, setVerifiedAge] = useLocalStorage({
    key: "verified-age",
    defaultValue: false,
  });

  return (
    <Modal
      opened={!verifiedAge}
      size="auto"
      onClose={close}
      centered
      withCloseButton={false}
      closeOnClickOutside={false}
      closeOnEscape={false}
      overlayProps={{
        backgroundOpacity: 1,
      }}
    >
      <Center maw={500}>
        <Stack align="center">
          <LogoTitle />
          <Title size="h3">Age Verification</Title>
          <Text ta="center">
            This website contains age-restricted materials including nudity and
            explicit depictions of sexual activity. By entering, you affirm that
            you are at least 18 years of age or the age of majority in the
            jurisdiction you are accessing the website from and you consent to
            viewing sexually explicit content.
          </Text>
          <Group justify="center">
            <Button
              onClick={() => {
                setVerifiedAge(true);
              }}
            >
              I am 18 or older - Enter
            </Button>
            <Anchor href="https://google.com" variant="outline">
              I am under 18 - Exit
            </Anchor>
          </Group>
        </Stack>
      </Center>
    </Modal>
  );
}
